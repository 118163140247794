import React, { useEffect, useState, useContext } from "react";
import svgNature from "../../assets/naturehistorical.svg";
import svgCulture from "../../assets/culturehistorical.svg";
import svgPdf from "../../assets/pdf.svg";
import svgPowerpoint from "../../assets/powerpoint.svg";
import svgMapstory from "../../assets/mapstory.svg";
import svgExternal from "../../assets/links.svg";
import svgTime from "../../assets/filter_time.svg";
import svgWhere from "../../assets/filter_where.svg";
import "./ArchiveSideBar.css";
import { AppContext } from "../../core/state/context";

export const ArchiveSideBar = () => {
const context = useContext(AppContext);

const articleFilter = context.articleFilter.value; 

  return (
    <div className="flex flex-col" style={{width: "20%" }}>
      {/* <div className="px-6 pt-6 font-bold text-lg">Periode</div>
      <div className="sidebar-filter-text">
            <div className="px-6 py-3 border-b border-grey flex flex-row items-center">
                  <img className="pr-3" src={svgTime} alt=""/>
                  Hva skjedde når?
            </div>
      </div> */}
      <div className="p-6 border-b border-grey flex flex-col">
            <div className="font-bold text-lg">Type</div>
            <div 
                  onClick={() => context.articleFilter.set({...articleFilter, nature: !articleFilter.nature})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: articleFilter.nature ? 1 : 0.5}}
            >
                 <img className="pr-3" src={svgNature} alt=""/>
                  Naturhistorisk
            </div>
            <div 
                  onClick={() => context.articleFilter.set({...articleFilter, culture: !articleFilter.culture})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: articleFilter.culture ? 1 : 0.5}}
            >                 <img className="pr-3" src={svgCulture} alt=""/>
                  Kulturhistorisk
            </div>
       </div>
      <div className="p-6 border-b border-grey flex flex-col">
            <div className="font-bold text-lg">Type innhold</div>
            <div 
                  onClick={() => context.articleFilter.set({...articleFilter, storymap: !articleFilter.storymap})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: articleFilter.storymap ? 1 : 0.5}}
            >
                 <img className="pr-3" src={svgMapstory} alt=""/>
                  Karthistorier
            </div>
            <div 
                  onClick={() => context.articleFilter.set({...articleFilter, pdf: !articleFilter.pdf})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: articleFilter.pdf ? 1 : 0.5}}
            >                 <img className="pr-3" src={svgPdf} alt=""/>
                  PDF
            </div>
            <div 
                  onClick={() => context.articleFilter.set({...articleFilter, powerpoint: !articleFilter.powerpoint})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: articleFilter.powerpoint ? 1 : 0.5}}
            >                 <img className="pr-3" src={svgPowerpoint} alt=""/>
                  Powerpoint
            </div>
            <div 
                  onClick={() => context.articleFilter.set({...articleFilter, external: !articleFilter.external})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: articleFilter.external ? 1 : 0.5}}
            >                 <img className="pr-3" src={svgExternal} alt=""/>
                  Eksterne artikler
            </div>
      </div>
    </div>
  );
};

import React, {useState, useContext} from "react";
import "./ThemesOverlay.css";
import svgRight from "../../assets/marker-right.svg";
import svgAdd from "../../assets/svgadd.svg";
import svgThemes from "../../assets/svgthemes.svg";
import { AppContext } from "../../core/state/context";
import { ITheme } from "../../core/model/interfaces";
import { useHistory, useLocation } from "react-router-dom";

export const ThemesOverlay = () => {
      const context = useContext(AppContext);
      const history = useHistory();
      const location = useLocation();

      const [themesVisible, setThemesVisible] = useState(true);
      const [allThemesVisible, setAllThemesVisible] = useState(false);

      const themes = context.themes.value;

      if (!themesVisible) {
            return (
                  <div onClick={() => setThemesVisible(true)} className="cursor-pointer theme-icon-open absolute m-6 p-6 right-0">
                        <img src={svgThemes} alt=""/>
                  </div>
            )
      }

  return (
        <div>
                  <div style={{top: allThemesVisible ? "0" : "-100%"}}className="theme-allthemes-container absolute h-full w-full top-0 left-0 z-20 flex flex-col">
                        <div className="theme-allthemes-header items-center flex flex-row px-32 text-4xl">
                              <div>
                                    Tema for kart
                              </div>
                              <div
                                    onClick={() => setAllThemesVisible(false)}
                                    className="theme-btn-close ml-auto cursor-pointer text-lg"
                              >
                                    LUKK
                              </div>
                        </div>
                        <div className="theme-allthemes flex flex-wrap px-32 overflow-auto">
                              {themes.length > 0 && themes.map((theme: ITheme, index: number) => {
                                    return (
                                          <div onClick={() => {
                                                history.push(`/map/${theme.attributes.OBJECTID}`, { prevPath: location.pathname });
                                                setAllThemesVisible(false);
                                          }} 
                                          key={index} className="theme-card-container h-32">
                                                <div className="cursor-pointer h-full theme-card rounded-lg flex flex-row rounded-lg">
                                                      <div className="theme-divider py-4 flex items-center rounded-lg rounded-r-none">
                                                                  <div className="py-4 theme-rotated">
                                                                        TEMA
                                                                  </div>
                                                      </div>
                                                      <div className="theme-card-text flex w-full h-auto items-center p-4 rounded-lg rounded-l-none">
                                                            {theme.attributes.Navn}
                                                      </div>
                                                </div>
                                          </div>
                                    );
                                    })
                              }
                        </div>
                  </div>
            <div>

            </div>
            <div className="absolute theme-cards flex flex-col p-6 right-0 z-10 h-full">
                  <div
                        className="cursor-pointer theme-card flex flex-row my-4 rounded-lg"
                        onClick={() => setThemesVisible(false)}
                        >
                        <div className="theme-divider flex items-center rounded-lg rounded-r-none">
                                    <img className="theme-icon-close mx-auto" src={svgRight}/> 
                        </div>
                        <div className="theme-card-text flex w-full h-full items-center p-4 rounded-lg rounded-l-none">
                              Skjul tema
                        </div>
                  </div>
                  {themes.length > 0 && themes.map((theme: ITheme, index: number) => {
                        if (theme.attributes.Forside === "Ja") {
                              return (
                                    <div onClick={() => history.push(`/map/${theme.attributes.OBJECTID}`)} className="cursor-pointer theme-card rounded-lg flex flex-row my-4 rounded-lg" key={theme.attributes.OBJECTID}>
                                          <div className="theme-divider py-4 flex items-center rounded-lg rounded-r-none">
                                                      <div className="py-4 theme-rotated">
                                                            TEMA
                                                      </div>
                                          </div>
                                          <div className="theme-card-text flex w-full h-full items-center p-4 rounded-lg rounded-l-none">
                                                {theme.attributes.Navn}
                                          </div>
                                    </div>
                              );
                        } else {
                              return null;
                        }
                  })
                  }
                  <div className="cursor-pointer theme-card flex flex-row my-4 rounded-lg">
                        <div className="theme-divider flex items-center rounded-lg rounded-r-none">
                                    <img className="theme-icon-close mx-auto" src={svgAdd}/> 
                        </div>
                        <div onClick={() => setAllThemesVisible(true)} className="theme-card-text flex w-full h-full items-center p-4 rounded-lg rounded-l-none">
                              Se flere
                        </div>
                  </div>
            </div>
      </div>
  );
};

import React, { useContext } from "react";
import { CardProps } from "../../core/model/interfaces";
import "./CardStyle.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import svgpdf from "../../assets/pdf.svg";
import svgpowerpoint from "../../assets/powerpoint.svg";
import svgExternal from "../../assets/links.svg";
import svgStorymap from "../../assets/mapstory.svg";
import { AppContext } from "../../core/state/context";

export const Card = (props: CardProps) => {
  const history = useHistory();
  const location = useLocation();
  const context = useContext(AppContext);
      
  const getIcon = () => {
    switch (props.icon) {
      case "Karthistorie": 
        return svgStorymap;
      case "PDF": 
        return svgpdf;
      case "PowerPoint": 
        return svgpowerpoint;
      case "Ekstern kilde": 
        return svgExternal;
    }
  }

  const onClickCard = () => {
    if (props.link) {
      history.push(props.link, { prevPath: location.pathname });
    }

    if (props.article) {
      context.article.set(props.article);
    }
  }

  return (
    <div 
      onClick={() => onClickCard()} 
      className="homepage-card cursor-pointer relative p-8 m-6 flex-grow"
      style={{flexBasis: props.flexBasis}} 
    >
      <div className="card-image absolute">
        <img className="h-full w-full rounded-lg object-cover" src={props.img}/>
      </div>
      <div>
        <img className="mt-6 ml-auto mt-auto" src={getIcon()}/>
      </div>
      <div className="card-text mb-auto mt-6">
        {props.text}
      </div>
    </div>
  );
};

import React, { useState, useContext } from "react";
import { IArticle, ArticleProps } from "../../core/model/interfaces";
import "./ArticleStyle.css";
import { Link, useLocation, useHistory } from "react-router-dom";

import svgpdf from "../../assets/pdf.svg";
import svgpowerpoint from "../../assets/powerpoint.svg";
import svgExternal from "../../assets/links.svg";
import svgStorymap from "../../assets/mapstory.svg";
import { AppContext } from "../../core/state/context";

export const ArticleView = () => {
      const context = useContext(AppContext);
      const history = useHistory();
      const location = useLocation();

      const [showArticle, setShowArticle] = useState(false);

      const getIcon = () => {
            switch (context.article.value.attributes.ArtikkelType) {
                  case "Karthistorie":
                        return svgStorymap;
                  case "PDF":
                        return svgpdf;
                  case "PowerPoint":
                        return svgpowerpoint;
                  case "Ekstern kilde":
                        return svgExternal;
            }
      }

      const getSource = () => {
            const article: IArticle = context.article.value;

            switch (article.attributes.ArtikkelType) {
                  case "Karthistorie":
                        return article.attributes.Storymap;
                  case "PDF":
                        return article.attributes.attachmentFiles[0];
                  case "PowerPoint":
                        return article.attributes.attachmentFiles[0];
                  case "Ekstern kilde":
                        return article.attributes.URL;
            }
      }

      const closeArticle = () => {
            setShowArticle(false);
            context.article.set(null);
      }

      const getOpenNewTab = () => {
            const article: IArticle = context.article.value;

            if (article.attributes.ArtikkelType !== "Ekstern kilde") {
                  return false;
            } else {
                  return context.isKiosk.value;
            }

            // Check if fullscreen here
            // return article.attributes.ArtikkelType === "Ekstern kilde" || !context.isKiosk.value;
      }

      const onOpenArticle = (article: IArticle) => {
            if (article.attributes.ArtikkelType !== "Ekstern kilde") {
                  setShowArticle(true);
            } else {
                  if (context.isKiosk.value) {
                        window.open(article.attributes.URL, "", "width=1000,height=500,top=200,menubar=off,toolbar=off");
                  } else{
                        window.open(article.attributes.URL);
                  }
            }
      }

      if (!context.article.value) {
            return null;
      }

      if (showArticle || location.pathname === "/" || location.pathname === "/map") {
            const article: IArticle = context.article.value;

            return (
                  <div className="h-full w-full fixed flex justify-center items-center z-30 bg-white-trans">
                        <div className="article-modal">
                              <div className="article-header text-2xl flex items-center p-6">
                                    {article.attributes.Navn}
                                    <div onClick={() => closeArticle()} className="ml-auto pr-3 cursor-pointer">
                                          Lukk
                              </div>
                              </div>
                              <iframe className="h-full w-full" src={getSource()} title="description" />
                        </div>
                  </div>
            )
      }

      if (!showArticle) {
            const article: IArticle = context.article.value;
            return (
                  <div className="h-full w-full fixed flex justify-center items-center">
                        <div className="article-popup">
                              <div className="article-header flex items-center pl-4">
                                    {article.attributes.Navn}
                              </div>
                              <div className="p-4">
                                    <div>
                                          {article.attributes.Beskrivelse}
                                    </div>
                                    <div className="font-bold">
                                          {article.attributes.Tidshenvisning}
                                    </div>
                                    <div className="flex flex-row pt-3">
                                          <div className="article-popup-button" onClick={() => {
                                                onOpenArticle(article);
                                          }}>Åpne</div>
                                          <div className="article-popup-button" onClick={() => context.article.set(null)}>Lukk</div>
                                          <img className="ml-auto" src={getIcon()} />
                                    </div>
                              </div>
                        </div>
                  </div>
            )
      }


      return <div>Hei</div>;

};

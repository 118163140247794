import axios from "axios";
import qs from 'querystring';
import { appSettings } from "../settings";
import { IArticle } from "../model/interfaces";


const config = {
      headers: {
        'Content-Type': 'application/json'
      }
  }

export const getArticles = (query: any): Promise<any> => {   
      let qparams = query;    
      return new Promise((resolve, reject) => {
          axios.get(`${appSettings.serviceBaseUrl}/${appSettings.articleServicePath}/query?${qs.stringify(qparams)}`, config)
          .then((results: any) => {
            if(results.data.error && results.data.error.code >= 400) {
              reject(results.data.error.code);
              return;
            }
          
            let objectids = results.data.features.map((article: IArticle) => article.attributes.OBJECTID).join(",");
            let attqp = Object.assign({}, { token: qparams.token, f: qparams.f })
            axios.get(`${appSettings.serviceBaseUrl}/${appSettings.articleServicePath}/queryAttachments?objectids=${objectids}&${qs.stringify(attqp)}`, config).then((attatchemts: any) => {
                let atts = attatchemts.data.attachmentGroups.map((attachment: any) => {
                    let feature = results.data.features.filter((article: IArticle) => {
                        return article.attributes.OBJECTID === attachment.parentObjectId;
                    })[0];
                    const attachmentUris = attachment.attachmentInfos.filter((attinfo: any) => ["image/jpeg", "image/png"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                      return `${appSettings.serviceBaseUrl}/${appSettings.articleServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${qparams.token}`;
                    });
                    feature.attributes.attachmentUris = attachmentUris.length > 0 ? attachmentUris : [];
                    
                    feature.attributes.attachmentFiles = attachment.attachmentInfos.filter((attinfo: any) => ["application/pdf"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                      return `${appSettings.serviceBaseUrl}/${appSettings.articleServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${qparams.token}`;
                    });
                })
                resolve(results);
            })
              
          });
      });
  }
  
  export const getAllArticles = (token: any): Promise<any> => {
  
    let query = {
      where: "1=1", // give us all themes
      outFields: "*",
      token: token,
      f: "json"
    };
    return new Promise((resolve, reject) => {
  
      getArticles(query).then((response) => {         
      let themes = response.data.features.map((article: any) => {
        Object.assign(article, { sourceLayer: {
          fields: response.data.fields,
          url: response.config.url.substring(0, response.config.url.match(/\/(\d+)\//).index+1),
          layerId: response.config.url.match(/\/(\d+)\//)[1]
        }})      
        return article;
      });
        resolve(themes);
      })
    })
  }
  
import React, { useEffect, useState, useContext } from "react";
import { FunFactCardProps } from "../../core/model/interfaces";
import "./CardStyle.css";
import { AppContext } from "../../core/state/context";
import { loadModules } from "esri-loader";

export const FunFactCard = (props: FunFactCardProps) => {
      const context = useContext(AppContext);

      const [funFacts, setFunFacts] = useState([]);
      const [activeFunFact, setActiveFunFact] = useState(null);

      useEffect(() => {
            if (funFacts.length === 0) {
              let servicesURL =
                "https://services9.arcgis.com/8Nu0wdjLqwKHS0Kd/arcgis/rest/services/Objekt_punkter/FeatureServer/0";
        
              servicesURL = servicesURL + "?token=" + context.token.value;
              loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]).then(
                ([QueryTask, Query]) => {
                  var queryTask = new QueryTask({
                    url: servicesURL,
                  });
                  var query = new Query();
                  query.returnGeometry = false;
                  query.outFields = ["Intro"];
                  query.where = "Fun_fact  = 1 AND Intro is not null"; 
        
                  queryTask.execute(query).then(function (results: any) {
                    let funFacts = results.features.map(
                      (feature: any) => feature.attributes.Intro
                    );
                    setFunFacts(funFacts);
                  });
                }
              );
            }
          }, []);

          useEffect(() => {
            getRandomFunFact();
          },[funFacts]);

      const getRandomFunFact = () => {
            setActiveFunFact(funFacts[Math.floor(Math.random() * funFacts.length)]);
      }

      return (
      <div onClick={() => getRandomFunFact()} className="cursor-pointer homepage-card relative flex flex-col p-6 m-6" style={{flexGrow: 3, flexBasis: 0}}>
            <div className="flex flex-row items-center">
              <div className="card-title text-xl pl-6">Visste du at...</div>
              <img className="ml-auto" src={props.icon}/>
            </div>
            <div className="flex flex-row h-full">
              <div className="funfact-text px-6 italic mb-auto">
                    {funFacts.length > 0 ? activeFunFact : ""}
              </div>
              <div className="funfact-more-text text-xl mt-auto ml-auto whitespace-no-wrap">
                    Vis neste
              </div>
            </div>
      </div>
      );
};

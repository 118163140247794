import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import svgBackArrow from "../../assets/backarrow.svg";
import svgNature from "../../assets/naturehistorical.svg";
import svgCulture from "../../assets/culturehistorical.svg";
import svgExplore from "../../assets/explorations.svg";
import svgTime from "../../assets/filter_time.svg";
import svgWhere from "../../assets/filter_where.svg";
import svgRemove from "../../assets/remove.svg";
import "./MapSideBar.css";
import { AppContext } from "../../core/state/context";
import { Filter } from "../Filter/Filter";
import { DomainValue } from "../../core/model/interfaces";

export const MapSideBar = () => {
      const location = useLocation();
      const context = useContext(AppContext);

      const [timeFilterVisible, setTimeFilterVisible] = useState(false);
      const [whereFilterVisible, setWhereFilterVisible] = useState(false);
    
      const layerFilter = context.layerFilter.value;

  return (
    <div className="relative flex flex-col" style={{width: "20%" }}>
      <Link
        to={{ pathname: "/", state: { prevPath: location.pathname } }}
        href="#"
      >
            <div className="p-6 border-b border-grey flex flex-row items-center" >
                 <img src={svgBackArrow} className="sidebar-back" alt="" />
                 <div className="sidebar-text-back ml-6">Meny</div>
            </div>
      </Link>
      <div className="p-6 border-b border-grey flex flex-col">
            <div className="font-bold text-lg">Vis i kartet</div>
            <div 
                  onClick={() => context.layerFilter.set({...layerFilter, nature: !layerFilter.nature})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: layerFilter.nature ? 1 : 0.5}}
            >
                 <img className="pr-3" src={svgNature} alt=""/>
                  Naturhistorisk
            </div>
            <div 
                  onClick={() => context.layerFilter.set({...layerFilter, culture: !layerFilter.culture})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: layerFilter.culture ? 1 : 0.5}}
            >                 <img className="pr-3" src={svgCulture} alt=""/>
                  Kulturhistorisk
            </div>
            <div 
                  onClick={() => context.layerFilter.set({...layerFilter, trips: !layerFilter.trips})}
                  className="sidebar-filter-active flex flex-row items-center p-4 my-2 cursor-pointer"
                  style={{opacity: layerFilter.trips ? 1 : 0.5}}
            >                 <img className="pr-3" src={svgExplore} alt=""/>
                  Turer
            </div>
      </div>
      <div className="sidebar-filter-text">
            <div className="border-b border-grey">
                  <div onClick={() => setTimeFilterVisible(!timeFilterVisible)} className="cursor-pointer px-6 py-3 flex flex-row items-center">
                        <img className="pr-3" src={svgTime} alt=""/>
                        Hva skjedde når?
                  </div>
                  <div className="flex flex-col">
                        {context.layerFilter.value.time.map((value: DomainValue) => {
                              return <div className="px-6 py-2 text-sm">
                                    {value.name}
                                    <img 
                                          className="cursor-pointer float-right"
                                          src={svgRemove}
                                          alt=""
                                          onClick={() => context.layerFilter.set({...context.layerFilter.value, time: context.layerFilter.value.time.filter((valueCurrent: DomainValue) => valueCurrent.code !== value.code )})}      
                                    />
                              </div>
                        })
                  }
                  </div>
            </div>
            <div onClick={() => setWhereFilterVisible(!whereFilterVisible)} className="cursor-pointer px-6 py-3 border-b border-grey flex flex-row items-center">
                  <img className="pr-3" src={svgWhere} alt=""/>
                  Hva finner jeg hvor?
            </div>
            <div className="flex flex-col">
                        {context.layerFilter.value.type.map((value: DomainValue) => {
                              return <div className="px-6 py-2 text-sm">
                                    {value.name}
                                    <img 
                                          className="cursor-pointer float-right"
                                          src={svgRemove}
                                          alt=""
                                          onClick={() => context.layerFilter.set({...context.layerFilter.value, type: context.layerFilter.value.type.filter((valueCurrent: DomainValue) => valueCurrent.code !== value.code )})}      
                                    />
                              </div>
                        })
                  }
                  </div>
      </div>
      <Filter 
            visible={timeFilterVisible}
            setVisible={setTimeFilterVisible}
            field="time"
            filter={context.layerFilter.value}
            setFilter={context.layerFilter.set}
            values={context.timePeriods.value}
      />    
      <Filter 
            visible={whereFilterVisible}
            setVisible={setWhereFilterVisible}
            field="type"
            filter={context.layerFilter.value}
            setFilter={context.layerFilter.set}
            values={context.objectTypes.value}
      />    
    </div>
  );
};

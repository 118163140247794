import axios from "axios";
import qs from 'querystring';
import { appSettings } from "../settings";
import { ITheme } from "../model/interfaces";
import { loadModules } from "esri-loader";
import { getArticles } from "./articleservice";

const config = {
      headers: {
        'Content-Type': 'application/json'
      }
  }

export const getThemes = (query: any): Promise<any> => {   
      let qparams = query;    
      return new Promise((resolve, reject) => {
          axios.get(`${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/query?${qs.stringify(qparams)}`, config)
          .then((results: any) => {
            if(results.data.error && results.data.error.code >= 400) {
              reject(results.data.error.code);
              return;
            }
          
            let objectids = results.data.features.map((theme: ITheme) => theme.attributes.OBJECTID).join(",");
            let attqp = Object.assign({}, { token: qparams.token, f: qparams.f })
            axios.get(`${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/queryAttachments?objectids=${objectids}&${qs.stringify(attqp)}`, config).then((attatchemts: any) => {
                let atts = attatchemts.data.attachmentGroups.map((attachment: any) => {
                    let feature = results.data.features.filter((theme: ITheme) => {
                        return theme.attributes.OBJECTID === attachment.parentObjectId;
                    })[0];
                    
                    const attachmentUris = attachment.attachmentInfos.filter((attinfo: any) => ["image/jpeg", "image/png"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                        return `${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${qparams.token}`;
                    });
                    feature.attributes.attachmentUris = attachmentUris.length > 0 ? attachmentUris : [];
                    
                    feature.attributes.attachmentFiles = attachment.attachmentInfos.filter((attinfo: any) => ["application/pdf"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                      return `${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${qparams.token}`;
                    });
                })
                resolve(results);
            })
              
          });
      });
  }
  
  export const getAllThemes = (token: any): Promise<any> => {
  
    let query = {
      where: "1=1", // give us all themes
      outFields: "*",
      token: token,
      f: "json"
    };
    return new Promise((resolve, reject) => {
  
    getThemes(query).then((response) => {         
      let themes = response.data.features.map((theme: any) => {
        Object.assign(theme, { sourceLayer: {
          fields: response.data.fields,
          url: response.config.url.substring(0, response.config.url.match(/\/(\d+)\//).index+1),
          layerId: response.config.url.match(/\/(\d+)\//)[1]
        }})      
        return theme;
      });
        resolve(themes);
      })
    })
  }
  
  export const getTheme = (id: string, token: any): Promise<any> => {   
    let query = {
      where: `OBJECTID=${id}`, // give us all themes
      outFields: "*",
      token: token,
      f: "json"
    };
    return new Promise((resolve, reject) => {
          axios.get(`${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/query?${qs.stringify(query)}`, config)
          .then((results: any) => {
            if(results.data.error && results.data.error.code >= 400) {
              reject(results.data.error.code);
              return;
            }
          
            let objectids = results.data.features.map((theme: ITheme) => theme.attributes.OBJECTID).join(",");
            let attqp = Object.assign({}, { token: query.token, f: query.f })
            axios.get(`${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/queryAttachments?objectids=${objectids}&${qs.stringify(attqp)}`, config).then((attatchemts: any) => {
                let atts = attatchemts.data.attachmentGroups.map((attachment: any) => {
                    let feature = results.data.features.filter((theme: ITheme) => {
                        return theme.attributes.OBJECTID === attachment.parentObjectId;
                    })[0];
                    
                    const attachmentUris = attachment.attachmentInfos.filter((attinfo: any) => ["image/jpeg", "image/png"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                        return `${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${query.token}`;
                    });
                    feature.attributes.attachmentUris = attachmentUris.length > 0 ? attachmentUris : [];
                    
                    feature.attributes.attachmentFiles = attachment.attachmentInfos.filter((attinfo: any) => ["application/pdf"].indexOf(attinfo.contentType.toLowerCase()) > -1).map((attinfo: any) => {
                      return `${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}/${feature.attributes.OBJECTID}/attachments/${attinfo.id}?token=${query.token}`;
                    });
                });

                let articleQuery = {
                  where: `Tema='${results.data.features[0].attributes.GlobalID}'`, 
                  outFields: "*",
                  token: token,
                  f: "json"
                };

                getArticles(articleQuery).then((articles) => {
                  resolve({
                    theme: results.data.features[0],
                    articles: articles.data.features
                  });
                })
            })
              
          });
      });
  }

  export const getThemeArticles = (id: string, token: string) => {
    return new Promise((resolve, reject) => {
      loadModules([
        "esri/tasks/QueryTask",
        "esri/tasks/support/RelationshipQuery",
      ]).then(([QueryTask, RelationshipQuery]) => {
        const relationshipQuery = new RelationshipQuery({
          relationshipId: 0,
          where: "1=1",
          outFields: ["*"],
          token: token,
          objectIds: [id],
        });
        const queryTask = new QueryTask({
          url: `${appSettings.serviceBaseUrl}/${appSettings.themeServicePath}`
        });
        queryTask
          .executeRelationshipQuery(relationshipQuery)
          .then((result: any) => {
            if(result[id]) {
              const articles = result[id].features;
              resolve(articles);
            } else {
              resolve([]);
            }
          });
      });
    });
  };
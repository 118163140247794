import React, { useContext, useEffect } from "react";
import { Card } from "../components/Card/Card";
import "./HomePageStyle.css";
import { AppContext } from "../core/state/context";
import { Link, useLocation, useHistory } from "react-router-dom";

import { FunFactCard } from "../components/Card/FunFactCard";

import { IArticle } from "../core/model/interfaces";

import svgCompass from "../assets/compass.svg";
import maskot from "../assets/maskot.svg";
import svginfo from "../assets/info.svg";
import bg from "../img/background.jpg";
import logo from "../img/kulnatur.png";

export const HomePage = () => {
  const location = useLocation();
  const context = useContext(AppContext);

  useEffect(() => {
    if (window.location.href.includes("https://kulnatur.randsfjordmuseet.geodataonline.no/")) {
      context.isKiosk.set(true);
    }
  }, []);

  const frontPageArticles: IArticle[] = context.articles.value.length > 0 ? 
    context.articles.value.filter((article: IArticle) => {return article.attributes.Forside === "Ja"}) : null;
  
  return (
    <div className="px-10 main-background w-full h-screen flex-row justify-center">
      <div className="homepage-header p-6 w-auto">
        <img src={logo} width={"20%"}/>
      </div>
      <Link
        to={{ pathname: "/map", state: { prevPath: location.pathname } }}
        href="#"
      >
        <div className="homepage-explore p-6 w-auto rounded-lg">
          <div className="homepage-explore-container p-10 flex flex-col">
            <div className="font-bold text-4xl uppercase flex">
              Klikk her
              <img className="ml-auto" src={svgCompass}/>
            </div>
            <div className="text-4xl uppercase">
              for å utforske i kartet
            </div>
              <div className="mt-auto text-2xl">
                Utforsk Randsfjordsområdet via kartet og oppdag spennende kulturhistoriske og naturhistoriske oppdagelser og historier. Kjenner du til vulkanene på Hadeland? Se om du kan finne dem…
              </div>
          </div>
        </div>
      </Link>
      <div className="w-auto homepage-cards flex">
        <Card 
          icon={frontPageArticles ? frontPageArticles[0].attributes.ArtikkelType : ""}
          img={frontPageArticles ? frontPageArticles[0].attributes.attachmentUris[0] : ""}
          text={frontPageArticles ? frontPageArticles[0].attributes.Navn : "Laster artikler.."}
          link={null}
          flexBasis={"0"}
          article={frontPageArticles ? frontPageArticles[0] : null}
        />
        <Card 
          icon={frontPageArticles ? frontPageArticles[0].attributes.ArtikkelType : ""}
          img={frontPageArticles ? frontPageArticles[1].attributes.attachmentUris[0] : ""}
          text={frontPageArticles ? frontPageArticles[1].attributes.Navn : "Laster artikler.."}
          link={null}
          flexBasis={"0"}
          article={frontPageArticles ? frontPageArticles[1] : null}

        />
        <Card 
          icon={frontPageArticles ? frontPageArticles[0].attributes.ArtikkelType : ""}
          img={bg}
          text="Utforsk arkivet"
          link="/archive"
          flexBasis={"0"}
          article={null}
        />
        <FunFactCard 
          icon={svginfo}
        />
      </div>
      <div className="homepage-footer relative m-6 w-auto" >
        <div className="homepage-footer-container absolute p-4 bottom-0 left-0 right-0">
          <img className="homepage-mascot" src={maskot}/>
        </div>
      </div>
    </div>
    
  );
};

import React, { useContext } from "react";
import { ArchiveSideBar } from "../components/ArchiveSideBar/ArchiveSideBar";
import { Link, useLocation, useHistory } from "react-router-dom";
import svgBackArrow from "../assets/backarrow.svg";
import { AppContext } from "../core/state/context";
import { IArticle } from "../core/model/interfaces";
import { Article } from "../components/Article/Article";

export const ArchivePage = () => {
  const history = useHistory();
  const location = useLocation();
  const context = useContext(AppContext);

  const articleFilter = context.articleFilter.value;
  const acceptedCats: string[] = [];
  const acceptedTypes: string[] = [];
  articleFilter.nature && acceptedCats.push("Naturskapte fenomener");
  articleFilter.culture && acceptedCats.push("Menneskeskapte fenomener");
  articleFilter.storymap && acceptedTypes.push("Karthistorie");
  articleFilter.pdf && acceptedTypes.push("PDF");
  articleFilter.powerpoint && acceptedTypes.push("PowerPoint"); 
  articleFilter.external && acceptedTypes.push("Ekstern kilde");

  const filteredArticles = context.articles.value.filter((article: IArticle) => {
    return (
      (acceptedCats.length > 0 ? acceptedCats.includes(article.attributes.Kategori) : true) &&
      (acceptedTypes.length > 0 ? acceptedTypes.includes(article.attributes.ArtikkelType) : true)
    );
  });

  return (
    <div className="flex flex-col h-screen">
      <div>
        <Link
          to={{ pathname: "/", state: { prevPath: location.pathname } }}
          href="#"
        >
              <div className="p-6 border-b border-grey flex flex-row items-center" >
                  <img src={svgBackArrow} className="sidebar-back" alt="" />
                  <div className="sidebar-text-back ml-6">Meny</div>
              </div>
        </Link>
      </div>
      <div className="flex flex-row overflow-scroll" style={{flex: "1"}}>
        <ArchiveSideBar/>
        <div className="w-full h-auto bg-gray-200 flex flex-wrap items-start">
          {filteredArticles.map((article: IArticle) => {
            return (
              <Article key={article.attributes.OBJECTID} article={article}/>
            )
          })}
        </div>
      </div>
    </div>
  );
};

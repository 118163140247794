export const appSettings: { [id: string]: any } = {
      serviceBaseUrl: "https://services9.arcgis.com/8Nu0wdjLqwKHS0Kd/arcgis/rest/services",
    //   agentsServicePath: "Skoler/FeatureServer/2",
      themeServicePath: "Tema_og_artikler/FeatureServer/0",
      articleServicePath: "Tema_og_artikler/FeatureServer/1",
      geoTripsServicePath: "Geotur/FeatureServer/1",
      geoTripStopsServicePath: "Geotur/FeatureServer/0",
      tripsServicePath: "Turer/FeatureServer/1",
    //   tripStopsServicePath: "Turer/FeatureServer/0",
    //   objectPoints: "Objekt_punkter/FeatureServer/0",
    //   objectPointsQuestions: "Objekt_punkter/FeatureServer/1",
    //   movementsTable: "/Turstopp_Bevegelser/FeatureServer/0",
      interceptableUrls: [
          "https://www.arcgis.com/sharing",
          "https://services9.arcgis.com"
      ],
  
      arcgisTokenUrl: 'https://www.arcgis.com/sharing/oauth2/token',
      
      arcgisApplication: {
          'client_id': "qHiL1rEcNJtJE88W",
          'client_secret': "4a5af7f92a9a47a49b5bfba5308a238a",
          'grant_type': "client_credentials"
      },
  
    //   friendlypagenames: {
    //       "/home": "Hjem",
    //       "/map": "Kart",
    //       "/": "Utforskning",
    //       "/tours": "Alle ekspedisjoner"
    //   }
  }
import React, { useEffect, useContext, useState } from "react";
import { loadModules, loadCss } from "esri-loader";
import * as conf from "../../config/MapConfig";
import { AppContext } from "../../core/state/context";
import { DomainValue } from "../../core/model/interfaces";
import { LayerList } from "../LayerList/LayerList";

export const WebMapView = () => {

  const context = useContext(AppContext);
  const [view, setView] = useState<any>();
  const [swipe, setSwipe] = useState<any>();
  const [lidArLayer, setLidArLayer] = useState<any>();

  useEffect(() => {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadCss();
    loadModules([
      "esri/WebMap",
      "esri/views/MapView",
      "esri/config",
      "esri/widgets/Expand",
      "esri/widgets/LayerList",
      "esri/widgets/BasemapGallery",
      "esri/widgets/Search",
      "esri/widgets/BasemapGallery/support/LocalBasemapsSource",
      "esri/Basemap",
      "esri/widgets/Swipe"
    ]).then(([EsriWebMap, MapView, esriConfig, Expand, LayerList, BasemapGallery, Search, LocalBasemapsSource, Basemap, Swipe]) => {
      esriConfig.request.interceptors?.push({
        urls: [
          "https://www.arcgis.com/sharing",
          "https://services9.arcgis.com",
        ],
        before: (params: any) => {
          params.requestOptions.query["token"] = context.token.value;
        },
        error: () => { },
      });

      var webmap: any = new EsriWebMap({
        portalItem: {
          id: conf.webmapid,
        },
      });

      // load the map view at the ref's DOM node
      const view = new MapView({
        container: "map",
        map: webmap,
        ui: {
          components: [],
        },
      });

      
      // view.popup.container.setAttribute("style", "display:none");
      
      view.when(() => {
        setView(view);
        setLayerFilter();
      });

      // Customizing popup
      view.when(() => {
        view.popup.dockEnabled = true;
        view.popup.dockOptions = {
          position: "top-center"
        };

        const pointLayerId = "Objekt_punkter_5719";

        // ------------------- Layer List

        var layerList = new LayerList({
          view: view,
          listItemCreatedFunction: function (event: any) {
            const item = event.item;
            if (item.layer.type != "group") {
              // don't show legend twice
              item.panel = {
                content: "legend",
                open: true
              };
            }
          }
        });
        const expandLayerList = new Expand({
          expandIconClass: "esri-icon-layer-list",
          expandTooltip: "Kartlagsliste",
          view: view,
          content: layerList,
          expanded: false,
          group: "top-left"
        });

        //  ------------ Basemap gallery
        var basemapBilder = new Basemap({
          portalItem: {
            id: "8bfed4e5d27e4d73b59fc192168843a2"
          }
        });

        var basemapTopo = new Basemap({
          portalItem: {
            id: "dd372c6aa6b843698368f52271df9132"
          }
        });


        var basemapGråtone = new Basemap({
          portalItem: {
            id: "9a15b511a4ea4a838fb5ffbd0c977c6a"
          }
        });

        const basemapGallery = new BasemapGallery({
          view: view,
          container: "basemapContainer",
          source: new LocalBasemapsSource({
            basemaps: [
              basemapGråtone,
              basemapTopo,
              basemapBilder
            ]
          })
        })
        // const expandBaseMap = new Expand({
        //   expandIconClass: "esri-icon-basemap",
        //   expandTooltip: "Bakgrunnskart",
        //   view: view,
        //   content: basemapGallery,
        //   expanded: false,
        //   group: "top-left"
        // });

        // Search

        const searchWidget = new Search({
          view: view
        });

        // Swipe
        const lidArLayer = view.map.layers.find((l: any) => l.title === "LIDAR");

        var swipe = new Swipe({
          view: view,
          leadingLayers: [],
          disabled: false,
          trailingLayers: [],
          direction: "horizontal", // swipe widget will move from top to bottom of view
          position: 50 // position set to middle of the view (50%)
        });
        setSwipe(swipe);
        setLidArLayer(lidArLayer);

        // view.ui.add([searchWidget, expandLayerList, expandBaseMap], "top-left");
        view.ui.add([searchWidget], "top-left");
        
        let pointLayer: any = view.map.layers.find((layer: any) => {
          return layer.id === pointLayerId;
        });
        view.whenLayerView(pointLayer).then((layerView: any) => {
          // Get domain values of timeperiods
          const timeFieldValues = pointLayer.fields.filter((field: any) => {
            return field.name === "Tidshenvisning";
          })[0].domain.codedValues;

          const formattedTimePeriods = timeFieldValues.map((value: any) => {
            return {
              code: value.code,
              name: value.name.split("(")[0],
              age: value.name.split("(")[1].replace(")", "")
            }
          })

          context.timePeriods.set(formattedTimePeriods);

          // Get domain values of object type
          const typeFieldValues = pointLayer.fields.filter((field: any) => {
            return field.name === "Type";
          })[0].domain.codedValues;

          context.objectTypes.set(typeFieldValues);

          // Legger til actions i popup
          const moreInfoAction = {
            title: "Se karthistorie",
            id: "storymap"
          }

          const externalAction = {
            title: "Åpne ekstern lenke",
            id: "external"
          }

          pointLayer.popupTemplate.overwriteActions = true;
          pointLayer.popupTemplate.actions = [moreInfoAction, externalAction];

          pointLayer.popupTemplate.expressionInfos = [
            // Expression for å hente ID på attachment
            {
              name: "attach",
              title: "",
              expression: (`
                Attachments($feature)[0].id
              `)
            },
            // Expression for å sjekke om det finnes en attachment
            {
              name: "attachCount",
              title: "",
              expression: (`
                IIf(Count(Attachments($feature)) > 0, "block", "none")
              `)
            },
          ]

          // På img: Vi setter display-style basert på om det finnes en attachment, hvis ja vises bildet 
          pointLayer.popupTemplate.content = [
            {
              type: "text",
              text: (`
              <div style="padding: 10px 0 0 0">
                <div style="font-weight: bold">
                  {Type}
                </div>
                <div style="padding: 0 0 20px 0">
                  {Tidshenvisning}
                </div>
                <div>
                 {Fritekst}
                </div>
                <div style="display: none;">
                  {URL} {Storymap}
                </div>
                <img style="padding: 20px 0 0 0; display: {expression/attachCount}" src="${pointLayer.url}/0/{OBJECTID}/attachments/{expression/attach}?token=${context.token.value}"/> 
              </div>`)
            }
          ]

          // Ved klikk på feature sjekker vi om det finnes Storymap eller URL. Hvis ikke skjules respektiv action
            view.popup.watch("selectedFeature", function (graphic: any) {
              if (graphic && graphic.layer.id === pointLayerId) {
                var graphicTemplate = graphic.getEffectivePopupTemplate();
                graphicTemplate.actions.items[0].visible = graphic.attributes.Storymap ? true : false;
                graphicTemplate.actions.items[1].visible = graphic.attributes.URL ? true : false;
              }
            });

          view.popup.viewModel.on("trigger-action", function (event: any) {
            var attributes = view.popup.viewModel.selectedFeature.attributes;

            if (event.action.id === "storymap") {
              context.article.set({
                geometry: {},
                attributes: {
                  Navn: attributes.Navn,
                  Storymap: attributes.Storymap,
                  ArtikkelType: "Karthistorie"
                }
              })
            } else if (event.action.id === "external") {
              if (context.isKiosk.value) {
                context.article.set({
                  geometry: {},
                  attributes: {
                    Navn: attributes.Navn,
                    URL: attributes.URL,
                    ArtikkelType: "Ekstern kilde"
                  }
                })
              } else {
                window.open(attributes.URL);
              }
            }
          });
        });
      });
    });
  }, []);

  // When changing layerFilter
  useEffect(() => {
    setLayerFilter();
  }, [context.layerFilter.value]);

  const setLayerFilter = () => {
    if (view) {
      const layerFilter = context.layerFilter.value;

      const pointLayerId = "Objekt_punkter_5719";
      const tourLayerId = "Geotur_8638";

      // Set points-defExpression
      const pointLayer: any = view.map.layers.find((layer: any) => {
        return layer.id === pointLayerId;
      });

      let objectDefExpression = "";

      if (layerFilter.nature && layerFilter.culture) {
        objectDefExpression = "1=1";
      } else if (layerFilter.nature) {
        objectDefExpression = "Kategori = '1'"
      } else if (layerFilter.culture) {
        objectDefExpression = "Kategori = '2'"
      } else {
        objectDefExpression = "1=2";
      }

      if (layerFilter.time.length > 0) {
        objectDefExpression += ` AND Tidshenvisning in (${layerFilter.time.map((value: DomainValue) => { return value.code })})`
      }

      if (layerFilter.type.length > 0) {
        objectDefExpression += ` AND Type in (${layerFilter.type.map((value: DomainValue) => { return value.code })})`
      }

      pointLayer.definitionExpression = objectDefExpression
      pointLayer.refresh();

      // Set trips-defExpression
      const tourLayer: any = view.map.layers.find((layer: any) => {
        return layer.id === tourLayerId;
      });

      tourLayer.visible = layerFilter.trips;
      tourLayer.refresh();
    }
  }

  return (<div className="w-full flex relative">
    <div className="w-full flex-grow" id="map" ></div>
    <LayerList
      view={view}
      swipe={swipe}
      lidArLayer={lidArLayer}
    />
  </div>
  );
}

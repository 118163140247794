import React from "react";
import "./LoadingAnimationStyle.css";

export const LoadingAnimation = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AppContext } from "../../core/state/context";
import './Theme.css';
import { getTheme } from "../../core/services/themeservice";
import { ITheme, IArticle } from "../../core/model/interfaces";
import { Card } from "../Card/Card";

export const Theme = (props: {id: string}) => {
      const location = useLocation();
      const context = useContext(AppContext);
      const history = useHistory();
      
      const [theme, setTheme] = useState<ITheme | null>(null);
      const [articles, setArticles] = useState<IArticle[]>([]);

      useEffect(() => {
            if (props.id) {
                  getTheme(props.id, context.token.value).then((result: any) => {
                        // console.log(result);
                        setTheme(result.theme);
                        setArticles(result.articles);
                  })
            }
      }, [props.id]);
      
      if (!props.id || !theme) {
            return null;
      }

  return (
    <div className="theme-container fixed h-full overflow-auto right-0 z-20">
      <div className="relative h-56">
            <div onClick={() => {
                  history.push(`/map`, { prevPath: location.pathname });
                  setTheme(null);
            }} className="theme-close-btn absolute right-0 top-0 z-50">Lukk</div>
            <img className="w-full h-full object-cover" src={theme.attributes.attachmentUris[0]}/>
            <div className="theme-header-text absolute top-0 left-0 p-10">
                  <div className="text-2xl">
                        {theme.attributes.Navn}
                  </div>
                  <div className="text-large">
                        {theme.attributes.Tidshenvisning}
                  </div>
            </div>
    </div>
    <div className="py-5">
      <div className="px-10 pb-4 font-bold">
            {theme.attributes.Intro}
      </div>
      <div className="px-10 pb-24">
            {theme.attributes.Beskrivelse}
      </div>
      <div className="flex flex-wrap">
            {articles.map((article: IArticle) => {
                  return (<Card 
                        text={article.attributes.Navn}
                        icon={article.attributes.ArtikkelType}
                        img={article.attributes.attachmentUris ? article.attributes.attachmentUris[0] : ""}
                        link={null}
                        flexBasis={"33.3333%"}
                        article={article}
                  />);
            } )}
      </div>
    </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { loadModules } from "esri-loader";
import { AppContext } from "./core/state/context";
import { gettoken } from "./core/services/token";
import { getAllThemes } from "./core/services/themeservice";

import { LoadingAnimation } from "./components/LoadingAnimation/LoadingAnimation";
import { MapPage } from "./screens/MapPage";
import { AboutPage } from "./screens/AboutPage";
import { ArchivePage } from "./screens/ArchivePage";
import { HomePage } from "./screens/HomePage";

import './App.css';
import { getAllArticles } from "./core/services/articleservice";
import { ArticleView } from "./components/Article/ArticleView";

function App() {

  const [token, setToken] = useState(null);
  const [layerFilter, setLayerFilter] = useState({
    nature: true,
    culture: true,
    trips: false,
    time: [],
    type: []
  });
  const [articleFilter, setArticleFilter] = useState({
    nature: false,
    culture: false,
    storymap: false,
    pdf: false,
    powerpoint: false,
    external: false
  });

  const [isKiosk, setIsKiosk] = useState(false);
  const [themes, setThemes] = useState([]);
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState();
  const [timePeriods, setTimePeriods] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);

  const store = {
    isKiosk: { value: isKiosk, set: setIsKiosk},
    token: { value: token, set: setToken },
    layerFilter: { value: layerFilter, set: setLayerFilter},
    themes: { value: themes, set: setThemes},
    articles: { value: articles, set: setArticles},
    articleFilter: { value: articleFilter, set: setArticleFilter},
    article: { value: article, set: setArticle},
    timePeriods: { value: timePeriods, set: setTimePeriods },
    objectTypes: { value: objectTypes, set: setObjectTypes }
  };

  useEffect(() => {
    loadModules(["esri/config"]).then(([esriConfig]) => {
      gettoken().then((data: any) => {
        store.token.set(data);

        // Get themes
        getAllThemes(data).then((result) => {
          setThemes(result);
        })

        // Get articles
        getAllArticles(data).then((result) => {
          setArticles(result);
        })
      });      
    });
  }, []);

  return (
    (store.token.value && (
      <AppContext.Provider value={store}>
        <Router>
          <div className="flex flex-col antialiased">
            <div className="app-background flex flex-col overflow-hidden h-screen">
              <Switch>
                <Route path="/map/:themeId?" component={MapPage} />
                <Route path="/about" component={AboutPage} />
                <Route path="/archive" component={ArchivePage} />
                <Route path="/" component={HomePage} />
              </Switch>
              <ArticleView/>
            </div>
          </div>
        </Router>
      </AppContext.Provider>
    )) || <LoadingAnimation />


  );
}

export default App;

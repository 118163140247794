import React, { useContext } from "react";
import { IArticle, ArticleProps } from "../../core/model/interfaces";
import "./ArticleStyle.css";
// import { Link, useLocation, useHistory } from "react-router-dom";
import svgnature from "../../assets/naturehistoricalwhite.svg";
import svgculture from "../../assets/culturehistoricalwhite.svg";
import svgpdf from "../../assets/pdfwhite.svg";
import svgpowerpoint from "../../assets/powerpoint.svg";
import svgExternal from "../../assets/linkswhite.svg";
import svgStorymap from "../../assets/mapstorywhite.svg";
import { AppContext } from "../../core/state/context";

export const Article = (props: ArticleProps) => {
  const context = useContext(AppContext);

const getIcon = () => {
  switch (props.article.attributes.ArtikkelType) {
    case "Karthistorie": 
      return svgStorymap;
    case "PDF": 
      return svgpdf;
    case "PowerPoint": 
      return svgpowerpoint;
    case "Ekstern kilde": 
      return svgExternal;
  }
}

const hasImage = props.article.attributes.attachmentUris;

  return (
    <div onClick={() => {context.article.set(props.article)}} key={props.article.attributes.OBJECTID} className="article-card relative cursor-pointer p-4" style={{height: "250px"}}>
      <img className="w-full h-full rounded-lg object-cover" src={hasImage ? props.article.attributes.attachmentUris[0] : ""}/>
      <div className="article-card-content text-xl flex flex-col absolute top-0 left-0 p-10 w-full h-full">
            {props.article.attributes.Navn}
            <div className="flex flex-row ml-auto mt-auto">
              <img className="px-4" src={getIcon()}/>
              <img src={props.article.attributes.Kategori === "Menneskeskapte fenomener" ? svgculture : svgnature}/>
            </div>
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import "./Filter.css";
import { AppContext } from "../../core/state/context";
import { FilterProps, DomainValue } from "../../core/model/interfaces";
import svgRemove from "../../assets/remove.svg";

export const Filter = (props: FilterProps) => {
      if(!props.visible) {
            return null;
      }

      const onClickRow = (value: DomainValue) => {
            if (props.filter[props.field].includes(value)) {
                  const filteredValues = props.filter[props.field].filter((v: DomainValue) => v !== value);
                  props.field === "time" ? props.setFilter({...props.filter, time: filteredValues}) : props.setFilter({...props.filter, type: filteredValues});
            } else {
                  props.field === "time" ? props.setFilter({...props.filter, time: [...props.filter[props.field], value]}) : props.setFilter({...props.filter, type: [...props.filter[props.field], value]})
            }
      }

  return (
    <div className="timefilter-container absolute h-full flex flex-col z-10">
          <div className="flex mr-auto filter-close-btn" onClick={() => props.setVisible(false)}>Lukk</div>
          {props.values.map((value: DomainValue) => {
                return <div 
                              onClick={() => onClickRow(value)} 
                              className="timefilter-timeperiod cursor-pointer py-2 mx-2 flex flex-row items-center"
                        >
                              <div className="flex flex-col">
                                    <div className="timefilter-title">
                                          {value.name}
                                    </div> 
                                    {value.age &&
                                          <div className="text-sm">
                                                {value.age}
                                          </div>
                                    }
                              </div>
                              {props.filter[props.field].includes(value) &&
                                    <img 
                                          className="ml-auto"
                                          src={svgRemove}
                                          alt=""
                                    />
                              }
                        </div>
          })}
    </div>
  );
};

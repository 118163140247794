import React, { useState, useContext, useEffect } from "react";
import "./LayerList.css";
import svgLayers from "../../assets/layers.svg";
import { AppContext } from "../../core/state/context";

export const LayerList = (props: { view: any, swipe: any, lidArLayer: any }) => {
      const context = useContext(AppContext);

      const view = props.view;

      const [layerListVisible, setLayerListVisible] = useState(false);
      const [rerenderer, setRerednerer] = useState(0);
      const [showSwipe, setShowSwipe] = useState(false);

      useEffect(() => {
            if (view) {
                  if (showSwipe) {
                        props.swipe.trailingLayers = [props.lidArLayer];
                        view.ui.add(props.swipe);
                        props.swipe.position = 51;
                  } else {
                        props.swipe.position = 0;
                        // props.swipe.trailingLayers = [];
                        view.ui.remove(props.swipe);
                        // view.map.add(props.lidArLayer);
                  }
            }
      }, [showSwipe])

      const toggleLayer = (layerName: string) => {
            const layer = view.map.layers.find((l: any) => l.title === layerName);
            if (layer) {
                  layer.visible = !layer.visible;
                  setRerednerer(rerenderer + 1);
            }
      }

      const getLayerStatus = (layerName: string) => {
            const layer = view.map.layers.find((l: any) => l.title === layerName);
            return layer.visible;
      }

      return (
            <div>
                  <div onClick={() => setLayerListVisible(true)} className="cursor-pointer layerlist-icon-open absolute m-4 p-6 left-0 flex justify-center">
                        <img src={svgLayers} alt="" />
                  </div>
                  <div className={`layerlist-container absolute left-0 h-full p-6 ${!layerListVisible && "hidden"}`}>
                        <div className="layerlist-header items-center flex flex-row pb-8 text-4xl">
                              <div>
                                    Kartlagsliste
                              </div>
                              <div
                                    onClick={() => setLayerListVisible(false)}
                                    className="layerlist-close ml-auto cursor-pointer text-lg"
                              >
                                    X
                              </div>
                        </div>
                        <div>
                              {view &&
                                    <div>
                                          <div className="layer-category py-4">
                                                <div>
                                                      Berggrunn
                                          </div>
                                                <div >
                                                      <div
                                                            className="layer-row flex items-center cursor-pointer"
                                                            onClick={() => toggleLayer("Berggrunn_N50 - BergartFlateN50")}
                                                      >
                                                            <input
                                                                  type="checkbox"
                                                                  checked={getLayerStatus("Berggrunn_N50 - BergartFlateN50")}
                                                            />
                                                            <div className="pl-2">
                                                                  Bergart Flate N50
                                                </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="layer-category py-4">
                                                <div>
                                                      LIDAR
                                          </div>
                                                <div >
                                                      <div
                                                            className="layer-row flex items-center cursor-pointer"
                                                            onClick={() => {setShowSwipe(false); toggleLayer("LIDAR")}}
                                                      >
                                                            <input
                                                                  type="checkbox"
                                                                  checked={getLayerStatus("LIDAR")}
                                                            />
                                                            <div className="pl-2">
                                                                  Laserdata
                                                      </div>
                                                      </div>
                                                      <div
                                                            onClick={() => getLayerStatus("LIDAR") && setShowSwipe(!showSwipe)}
                                                            className={`flex flex-row items-center pl-4 ${!getLayerStatus("LIDAR") && "disabled"}`}
                                                      >
                                                            <input
                                                                  type="checkbox"
                                                                  checked={showSwipe}
                                                            />
                                                            <div className="pl-2">
                                                                  Sammenlign
                                                      </div>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="basemapContainer" id="basemapContainer">

                                          </div>
                                    </div>
                              }
                        </div>
                  </div>
            </div>
      )
};

import React from "react";
import { WebMapView } from "../components/WebMap/WebMapView";
import { MapSideBar } from "../components/MapSideBar/MapSideBar";
import { ThemesOverlay } from "../components/ThemesOverlay/ThemesOverlay";
import { RouteComponentProps } from "react-router-dom";
import { Theme } from "../components/Theme/Theme";
import { LayerList } from "../components/LayerList/LayerList";

export const MapPage = ({ match }: RouteComponentProps<any>) => {
  return (
    <div className="flex flex-grow overflow-hidden">
      <MapSideBar/>
      <WebMapView/>
      <ThemesOverlay/>
      <Theme id={match.params.themeId}/>
    </div>
  );
};

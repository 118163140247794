import axios from "axios";
import qs from 'querystring';
import { appSettings } from "../settings";

export const gettoken = (): Promise<any> => {   
    let params = appSettings.arcgisApplication;

    const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    return new Promise(resolve => {

        if(sessionStorage.getItem("token_data")) {
            let token = JSON.parse(sessionStorage.getItem("token_data")!);
            console.log(token);
            if(token.expiry > new Date().getTime()) {
                resolve(token.token);
                return;
            }            
        }

        axios.post(appSettings.arcgisTokenUrl, qs.stringify(params), config).then((results) => {
            sessionStorage.setItem("token_data", JSON.stringify({ expiry: new Date().getTime() + parseInt(results.data["expires_in"]), token: results.data["access_token"] }));
            resolve(results.data["access_token"]);
        })
    })
}